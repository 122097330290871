import React, { useCallback, useEffect, useState } from 'react'

import { DefaultModal } from 'components/Modals/lugarh/Default/DefaultModal'

import './DuplicateWorkspace.style.scss'
import SingleSelectableList from 'components/SelectableList/lugarh/SingleSelectableList'
import { useStoreNotification } from 'hooks/useStoreNotification'
import { jobs as jobsAPI } from 'services/api'
import * as wsApi from 'services/api/workspaces/workspaces'

import { useTranslation } from 'react-i18next'

export type ModalType = 'company' | 'workspace'
export interface DuplicateWorkspaceModalProps {
  open: boolean
  onClose: () => void
  customClass?: string
  jobUuid: string
}

export const DuplicateWorkspace: React.FC<DuplicateWorkspaceModalProps> = ({
  open,
  onClose,
  customClass = '',
  jobUuid
}) => {
  const [data, setData] = useState<WorkspaceSchema[]>()
  const [selected, setSelected] = useState<string>()
  const [loading, setLoading] = useState<boolean>()
  const { showNotification } = useStoreNotification()
  const { t } = useTranslation('duplicateWorkspaceModal')
  const { t: te } = useTranslation('error')
  const { t: ts } = useTranslation('success')

  const fetchWorkspaces = async () => {
    const response = await wsApi.list()
    setData(response.data)
  }

  const handleFormSubmit = async () => {
    const selectedWorkspace = data?.find(
      workspace => workspace.uuid === selected
    )

    if (selected && selectedWorkspace && !loading) {
      setLoading(true)

      try {
        const [error] = await jobsAPI.update(
          {
            work_space: selectedWorkspace.uuid,
            active: true
          },
          jobUuid
        )
        if (error) {
          if (typeof error === 'string') {
            const errorMessage =
              error === 'The server was unable to complete your request.'
                ? 'serverError'
                : error
            showNotification({ message: te(errorMessage), type: 'error' })
          } else {
            showNotification({ message: te(error.message), type: 'error' })
          }
        }
        showNotification({ message: ts('jobDuplicated'), type: 'success' })
      } catch (error: unknown) {
        const errorMessage = (error as Error).message
        showNotification({ message: errorMessage, type: 'error' })
      }

      setLoading(false)
    }
  }

  const handleSelectChange = useCallback((selected: any) => {
    setSelected(selected)
  }, [])

  const convertData = useCallback(
    (data: WorkspaceSchema[]): Workspace[] =>
      data.map(workspace => ({
        uuid: workspace.uuid,
        name: workspace.name,
        slug: workspace.slug || ''
      })),
    []
  )

  // Só faz o fetch se a modal estiver aberta
  useEffect(() => {
    open && fetchWorkspaces()
  }, [open])

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      customClass={`ce-modal ${customClass}`}
      modalTitle={t('title')}
    >
      <DefaultModal.Content customClass='form'>
        <div className='form__step-2'>
          <div className='mui-modal__list ce-modal__list'>
            <SingleSelectableList<Workspace>
              items={convertData(data || [])}
              onSelect={handleSelectChange}
              labels={['name', 'slug', 'uuid']}
              itemIdentifier='uuid'
              selected={selected || ''}
            />
          </div>
        </div>
        <div className='ce-modal__footer'>
          <button
            className='mui-modal__button btn__primary'
            onClick={handleFormSubmit}
          >
            {t('button')}
          </button>
        </div>
      </DefaultModal.Content>
    </DefaultModal>
  )
}
