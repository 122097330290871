/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { string } from 'yup'

import { ReactComponent as ArrowDownIcon } from 'assets/images/icons/arrow-down-icon.svg'
import { ReactComponent as ArrowUpIcon } from 'assets/images/icons/arrow-up-icon.svg'
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy-icon.svg'
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-icon.svg'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { Switch } from 'components/Switch/Switch'
import { TextArea } from 'components/TextArea/TextArea'

import './CustomQuestions.scss'

export type Questions = {
  description: string
  eliminate: boolean
  type: 'multiple_choice' | 'selectable_multiple_choice' | 'open_ended'
  answers?: Array<Answers>
  key?: number
}

export type Answers = {
  description: string
  right_answer: boolean
}

type Props = {
  onDeleteQuestion: () => void
  onDuplicateQuestion: () => void
  onMoveUpQuestion: () => void
  onMoveDownQuestion: () => void
  onChange: (e: Questions) => void
  onError: (e: boolean) => void
  value?: Questions
  key?: number | string
  edit?: boolean
  hasApplications?: boolean
  blockButtons?: { up: boolean; down: boolean }
}

const Responses = ({
  onChange,
  value,
  onInvalid,
  hasApplications = false
}: {
  onChange: (value: string) => void
  value: string
  onInvalid: (value: boolean) => void
  hasApplications: boolean
}) => {
  const [description, setDescription] = useState(value)
  const [validAnswers, setValidAnswers] = useState(true)
  const [descriptionTouched, setDescriptionTouched] = useState(false)

  const { t } = useTranslation('customQuestions')

  useEffect(() => {
    onChange(description)
    setValidAnswers(string().required().isValidSync(description))
    onInvalid(!string().required().isValidSync(description))
  }, [description])

  return (
    <div className='unique-response-wrapper'>
      <div className='ul' />
      <Input
        label=''
        name='description'
        type='text'
        value={description}
        disabled={hasApplications}
        onBlur={() => setDescriptionTouched(true)}
        error={!validAnswers && descriptionTouched ? t('required') : ''}
        onChange={setDescription}
      />
    </div>
  )
}

export const CustomQuestions: React.FC<Props> = ({
  onDeleteQuestion,
  onDuplicateQuestion,
  onMoveUpQuestion,
  onMoveDownQuestion,
  onChange,
  onError,
  value,
  edit,
  blockButtons,
  hasApplications = false
}) => {
  const [description, setDescription] = useState('')
  const [questions, setQuestions] = useState<Questions>({} as Questions)
  const [answers, setAnswers] = useState<Array<Answers>>([
    { description: '', right_answer: false }
  ] as Array<Answers>)
  const [eliminate, setEliminate] = useState(false)
  const [type, setType] = useState<
    'multiple_choice' | 'selectable_multiple_choice' | 'open_ended'
  >('open_ended')

  const [invalidDescription, setInvalidDescription] = useState(false)
  const [invalidAnswer, setInvalidAnswer] = useState(false)
  const [descriptionTouched, setDescriptionTouched] = useState(false)

  const { t } = useTranslation('customQuestions')

  const isHiddenButton = (type: string, valueType: string) => {
    if (edit && hasApplications) {
      return type === valueType
        ? { display: 'block', width: 130 }
        : { display: 'none' }
    } else {
      return {}
    }
  }

  useEffect(() => {
    if (questions.type === 'open_ended') delete questions.answers
    onChange(questions)
    const isDescriptionValid = string()
      .required()
      .isValidSync(questions.description)
    setInvalidDescription(isDescriptionValid)
  }, [questions])

  useEffect(() => {
    onError(invalidDescription || invalidAnswer)
  }, [invalidDescription, invalidAnswer])

  useEffect(() => {
    setDescription(value?.description || '')
    setEliminate(value?.eliminate || false)
    setType(value?.type || 'open_ended')
    setAnswers(value?.answers || [{ description: '', right_answer: false }])
  }, [])

  useEffect(() => {
    setQuestions({
      ...questions,
      ...{ description },
      ...{ answers },
      ...{ type },
      ...{ eliminate }
    })
  }, [answers, type, eliminate, description])

  return (
    <div className='custom-questions-wrapper'>
      <div className='row'>
        <div
          className={classNames(
            { 'col-10': type === 'multiple_choice' },
            { 'col-12': type !== 'multiple_choice' }
          )}
        >
          <p className='title'>{t('question')}</p>
          <TextArea
            label=''
            name='question'
            disabled={hasApplications}
            value={description}
            onChange={setDescription}
            onBlur={() => setDescriptionTouched(true)}
            error={
              invalidDescription && descriptionTouched ? t('required') : ''
            }
          />
        </div>
        <div
          className={classNames(
            {
              'col-2 d-flex align-items-center justify-content-center':
                type === 'multiple_choice'
            },
            { 'd-none': type !== 'multiple_choice' }
          )}
        >
          <div className='d-flex-column align-items-center justify-content-center'>
            <p className='title'>{t('eliminate')}</p>
            <Switch
              initialValue={eliminate}
              disabled={hasApplications}
              onChange={e => {
                setEliminate(e)
              }}
            />
          </div>
        </div>

        <div className='mt-3 col-12'>
          <div className='row'>
            <div className='col-md-12 col-lg-5 d-flex'>
              <Button
                style={isHiddenButton(type, 'multiple_choice')}
                className={type === 'multiple_choice' ? 'primary' : 'none'}
                disabled={hasApplications}
                onClick={() => {
                  setType('multiple_choice')
                  setAnswers([{ description: '', right_answer: true }])
                  setEliminate(false)
                }}
              >
                {t('unique')}
              </Button>
              <Button
                style={isHiddenButton(type, 'selectable_multiple_choice')}
                className={
                  type === 'selectable_multiple_choice' ? 'primary' : 'none'
                }
                disabled={hasApplications}
                onClick={() => {
                  setType('selectable_multiple_choice')
                  setAnswers([{ description: '', right_answer: false }])
                  setEliminate(false)
                }}
              >
                {t('mult')}
              </Button>
              <Button
                style={isHiddenButton(type, 'open_ended')}
                className={type === 'open_ended' ? 'primary' : 'none'}
                disabled={hasApplications}
                onClick={() => {
                  setType('open_ended')
                  setAnswers([])
                  setEliminate(false)
                }}
              >
                {t('opened')}
              </Button>
            </div>
          </div>
        </div>

        <div className='col-12'>
          {type !== 'open_ended' && (
            <div className='row'>
              <div className='col-12'>
                <p className='title'>{t('answers')}</p>
                {answers.map((el, index) => (
                  <div className='row' key={index}>
                    <div
                      className={classNames(
                        {
                          'col-10 col-lg-11':
                            type === 'selectable_multiple_choice'
                        },
                        {
                          'col-8 col-lg-10': type === 'multiple_choice'
                        }
                      )}
                    >
                      <Responses
                        value={answers[index].description}
                        onInvalid={setInvalidAnswer}
                        hasApplications={hasApplications}
                        onChange={e => {
                          const newArr = [...answers]
                          newArr[index] = {
                            description: e,
                            right_answer: newArr[index].right_answer
                          }
                          setAnswers(newArr)
                        }}
                      />
                    </div>

                    <div
                      className={classNames(
                        'actions',
                        {
                          'col-2 col-lg-1':
                            type === 'selectable_multiple_choice'
                        },
                        {
                          'col-4 col-lg-2': type === 'multiple_choice'
                        }
                      )}
                    >
                      <div
                        className={classNames('col-6', {
                          'd-none': type !== 'multiple_choice'
                        })}
                      >
                        {index === 0 && type === 'multiple_choice' && (
                          <p className='title mt-n'>{t('rightAnswer')}</p>
                        )}
                        {type === 'multiple_choice' && (
                          <label className='radio'>
                            <input
                              type='radio'
                              checked={el.right_answer}
                              name={`${value?.key}`}
                              disabled={hasApplications}
                              onChange={() => {
                                const newArr = [...answers]
                                newArr.map(el => {
                                  return (el.right_answer = false)
                                })
                                newArr[index] = {
                                  description: newArr[index].description,
                                  right_answer: true
                                }
                                setAnswers(newArr)
                              }}
                            />
                            <span className='checkmark' />
                          </label>
                        )}
                      </div>

                      <div
                        className={classNames(
                          'd-flex justify-content-end p-0',
                          { 'col-12': type === 'selectable_multiple_choice' },
                          { 'col-6': type === 'multiple_choice' }
                        )}
                      >
                        {index + 1 !== answers.length && (
                          <Button
                            className='primary d-flex justify-content-center align-items-center danger'
                            onClick={() => {
                              const newArr = [...answers]

                              newArr.splice(index, 1)

                              if (
                                newArr.length &&
                                !newArr.some(el => el.right_answer)
                              ) {
                                newArr[0].right_answer = true
                              }

                              setAnswers(newArr)
                            }}
                            disabled={hasApplications}
                          >
                            -
                          </Button>
                        )}

                        {index + 1 === answers.length && (
                          <Button
                            className='primary d-flex justify-content-center align-items-center'
                            onClick={() => {
                              const newArr = [...answers]
                              newArr.push({
                                description: '',
                                right_answer: false
                              })
                              setAnswers(newArr)
                            }}
                            disabled={hasApplications}
                          >
                            +
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-12 d-flex justify-content-end'>
          <div className='actions'>
            <Button
              className='primary'
              onClick={onMoveUpQuestion}
              data-tooltip={t('moveUp')}
              disabled={blockButtons?.up || hasApplications}
            >
              <ArrowUpIcon />
            </Button>
            <Button
              className='primary'
              onClick={onMoveDownQuestion}
              data-tooltip={t('moveDown')}
              disabled={blockButtons?.down || hasApplications}
            >
              <ArrowDownIcon />
            </Button>
            <Button
              onClick={onDuplicateQuestion}
              data-tooltip={t('duplicate')}
              disabled={hasApplications}
            >
              <CopyIcon />
            </Button>
            <Button
              className='danger'
              onClick={onDeleteQuestion}
              data-tooltip={t('delete')}
              disabled={hasApplications}
            >
              <TrashIcon />
            </Button>
          </div>
        </div>
      </div>

      <div className='w-10 b' />
    </div>
  )
}
