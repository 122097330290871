import React, { useMemo } from 'react'
import './Loading.style.scss'

interface LoadingProps {
  className?: string
  template?: 'default' | 'only-bars'
}

export const Loading: React.FC<LoadingProps> = ({
  className = '',
  template = 'dafault'
}) => {
  const loadingBars = useMemo(
    () => (
      <div className={`loader__bars ${template !== 'default' && className}`}>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
        <div className='loader__bar'></div>
      </div>
    ),
    []
  )

  return template === 'default' ? (
    <div className={`loader ${className}`}>{loadingBars}</div>
  ) : (
    loadingBars
  )
}
