import { dark } from '@mui/material/styles/createPalette'

export enum Theme {
  RECRUD = 'recrud',
  LUGARH = 'lugarh',
  CUSTOM = 'custom'
}

export const defaultColors = {
  [Theme.RECRUD]: {},
  [Theme.LUGARH]: {
    primary: '#950099',
    primary_dark: '#5a005d',
    primary_light: '#cb00d2',
    secondary: '#0288D1',
    secondary_dark: '#01579B',
    secondary_light: '#03A9F4',
    tertiary: '#6AC425',
    tertiary_dark: '#5EAE21',
    tertiary_light: '#7FCD43',
    background_light: '#ffffff',
    text_primary: '#141314',
    mid_grey: '#0000001F',
    dark_grey: '#00000061'
  },
  [Theme.CUSTOM]: {}
}

export const themeClasses: ThemeClasses = {
  [Theme.RECRUD]: 'theme-recrud',
  [Theme.LUGARH]: 'theme-lugarh',
  [Theme.CUSTOM]: 'theme-lugarh theme-custom'
}
