import React from 'react'

import classNames from 'classnames'

import './Notification.scss'
import { useTranslation } from 'react-i18next'

import useStore from 'store'

import { ErrorIcon } from './assets/ErrorIcon'
import { SuccessIcon } from './assets/SuccessIcon'

export const Notifications: React.FC = () => {
  const { selectors } = useStore()
  const hasMessage = selectors.notification.hasMessage()
  const messages = selectors.notification.messages()
  const { t } = useTranslation('notifications')

  const [message] = messages

  if (!hasMessage) {
    return null
  }

  return (
    <div className='notification__container'>
      <div className='notification__wrapper'>
        <div className='notification__icon-bg-container'>
          <div
            className={`notification__icon-bg${
              hasMessage ? ` notification__icon-bg--${message.type}` : ''
            }`}
          ></div>
        </div>
        {hasMessage && message.type === 'success' && <SuccessIcon />}
        {hasMessage && message.type === 'error' && <ErrorIcon />}
        <div className='notification__message'>
          {hasMessage ? message.message : ''}
          {hasMessage && message.link && (
            <a href={message.link} className='notification__link'>
              {message.linkLabel
                ? message.linkLabel
                : t('lugarh.goToLinkLabel')}
            </a>
          )}
          {hasMessage && message.observation && (
            <small className='notification__observation'>
              {message.observation}
            </small>
          )}
        </div>
      </div>
    </div>
  )
}
