import React, { useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/Avatar/Avatar'
import {
  HiringStepHistory,
  Stage
} from 'components/HiringStepHistory/HiringStepHistory'
import {
  RecruiterAnswers,
  Answer
} from 'components/RecruiterAnswers/RecruiterAnswers'
import { RecruiterNotes, Note } from 'components/RecruiterNotes/RecruiterNotes'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'

import './HistoryContainer.scss'

export interface LogsSchemaWithDescription extends LogsSchema {
  description: string
}

export type History = {
  candidateName: string
  applicationUUID: string
  jobUUID: string
  jobName: string
  jobAppliedIn: Date
  review: number
  notes: Note[]
  answers: Answer[]
  logs: LogsSchemaWithDescription[]
  stages: WorkflowStageSchema[]
  workspace: WorkspaceSchema
}

interface HistoryItemProps {
  name: string
  opportunity: string
  workspace: WorkspaceSchema
  date: Date
  review: number
  answers: Array<Answer>
  noteList: Array<Note>
  stepList: Array<Stage>
  stepHistoryList: Array<LogsSchemaWithDescription>
  onMoveToStep: (step: string) => void
  onSendNote: (note: string) => Promise<boolean>
  onAddReview: (review: number) => void
  getRefs: (value: { historyRef; noteRef }) => void
}

const HistoryItem: React.FC<HistoryItemProps> = ({
  name,
  opportunity,
  workspace,
  date,
  review,
  answers,
  noteList,
  stepList,
  stepHistoryList,
  onMoveToStep,
  onSendNote,
  onAddReview,
  getRefs
}) => {
  const { t } = useTranslation('historyContainer')
  const historyRef = useRef<HTMLDivElement>(null)
  const noteRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getRefs({ historyRef, noteRef })
  }, [historyRef, noteRef])

  return (
    <WidgetBox
      widgetBoxClasses='no-padding'
      widgetBoxtContentClasses=' px-4'
      className='py-4 mb-4'
    >
      <div className='user-status'>
        <Avatar user={name} />
        <p className='user-status-title medium'>
          <a className='bold'>{name}</a> {` ${t('appliedForJob')} `}
          <span className='bold'>{opportunity}</span>
          {` ${t('onWorkspace')} `}
          <span className='bold'>{workspace.name}</span>
        </p>
        <p className='user-status-text small'>{t('onDate', { date })}</p>
      </div>

      <div ref={historyRef}>
        <HiringStepHistory
          stepList={stepList}
          stepHistoryList={stepHistoryList}
          onMoveToStep={onMoveToStep}
          jobWorkspaceId={workspace.uuid}
        />
      </div>

      {!!answers.length && <RecruiterAnswers answers={answers} />}

      <div ref={noteRef}>
        <RecruiterNotes
          notes={noteList}
          onSendNote={onSendNote}
          jobName={opportunity}
          jobWorkspaceId={workspace.uuid}
          review={review}
          onAddReview={onAddReview}
        />
      </div>
    </WidgetBox>
  )
}

export const HistoryContainer: React.FC<{
  getRefs: (value: { historyRef; noteRef }) => void
  history: History
  onSendNote: (note: string) => Promise<boolean>
  onAddReview: (review: number) => void
  onMoveToStep: (stage: string) => void
}> = ({ getRefs, history, onSendNote, onMoveToStep, onAddReview }) => {
  const stepList = history.stages?.map(stage => ({
    name: stage.name,
    id: stage.uuid
  }))

  return (
    <div>
      <HistoryItem
        stepList={stepList || []}
        stepHistoryList={history.logs}
        noteList={(history.notes || []) as Note[]}
        name={history.candidateName}
        opportunity={history.jobName}
        workspace={history.workspace}
        date={history.jobAppliedIn}
        review={history.review}
        answers={history.answers}
        onMoveToStep={onMoveToStep}
        onSendNote={onSendNote}
        onAddReview={onAddReview}
        getRefs={getRefs}
      />
    </div>
  )
}
