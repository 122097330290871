import moment from 'moment'

import i18n from 'i18n'

/**
 * Provides the date format based on the current language.
 * If the language is set to 'pt-BR', the format will be 'DD/MM/YYYY'.
 * Otherwise, the format will be 'MM/DD/YYYY'.
 * @returns The date format as a string.
 */
export const getDateFormat = (): string => {
  return i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
}

/**
 * Converts a timestamp to a formatted date string.
 * @param timestamp - The timestamp to convert.
 * @returns The formatted date string.
 * @example timestampToDate(1614476400) -> '28/02/2021'
 */
export const timestampToDate = (timestamp: number): string => {
  return moment.unix(timestamp).utc().format(getDateFormat())
}

/**
 * Converts a Unix timestamp to a date and time string.
 *
 * @param timestamp - The Unix timestamp to convert.
 * @returns An object containing the formatted date and time strings.
 * @example timestampToDateTime(1614476400) -> { date: '28/02/2021', time: '14:00' }
 */
export const timestampToDateTime = (
  timestamp: number
): { date: string; time: string } => {
  const date = moment.unix(timestamp).utc().format(getDateFormat())
  const time = moment.unix(timestamp).utc().format('HH:mm')
  return { date, time }
}

/**
 * Converts a timestamp to a formatted date string to use into new Date.
 * @param timestamp - The timestamp to convert.
 * @returns The formatted date string.
 * @example timestampToNewDate(1614476400) -> '2021-02-28'
 */
export const timestampToNewDate = (timestamp: number): string => {
  return moment.unix(timestamp).utc().format('YYYY-MM-DD')
}

/**
 * Converts a date to a Unix timestamp.
 * @param dateInput - The date to convert. It can be a string, number, or Date object.
 * @returns The Unix timestamp representing the given date. If the conversion fails, it returns 0.
 * @example dateToTimestamp('28/02/2021') -> 1614476400
 */
export const dateToTimestamp = (dateInput: string | number | Date): number => {
  const dateIsString = typeof dateInput === 'string'
  const dateIsNumber = typeof dateInput === 'number'
  const dateIsDate = dateInput instanceof Date

  let dateMoment

  if (dateIsString) dateMoment = moment.utc(dateInput, getDateFormat())
  if (dateIsNumber || dateIsDate) dateMoment = moment.utc(dateInput)

  return dateMoment?.unix() || 0
}

export const getFullYear = (date: string): number => {
  return moment(date, getDateFormat()).year()
}

export const handleDateStr = (value: string): string | undefined => {
  const hasNonNumeric = /[^0-9/]/.test(value)
  if (value === '//' || hasNonNumeric) return undefined
  return value
}

/**
 * Calculates the age based on a given timestamp.
 *
 * @param {number} timestamp - The timestamp in MILLISECONDS representing the birth date.
 * @returns {number} - The calculated age in years.
 */
export const calculateAge = (timestamp: number): number => {
  // Verifica se o timestamp está em segundos (menor que 10^10)
  const isSeconds = timestamp < 1e10
  const birthDate = isSeconds ? moment.unix(timestamp) : moment(timestamp)
  return moment().diff(birthDate, 'years')
}

export const getTimeDifference = (timestamp1: number, timestamp2: number) => {
  let date1 = new Date(timestampToNewDate(timestamp1))
  let date2 = new Date(timestampToNewDate(timestamp2))

  if (date1 > date2) {
    ;[date1, date2] = [date2, date1]
  }

  let years = date2.getFullYear() - date1.getFullYear()
  let months = date2.getMonth() - date1.getMonth()
  let days = date2.getDate() - date1.getDate()

  if (days < 0) {
    months--
    const lastMonthDate = new Date(date2.getFullYear(), date2.getMonth(), 0)
    days += lastMonthDate.getDate()
  }

  if (months < 0) {
    years--
    months += 12
  }

  return {
    years,
    months,
    days
  }
}

export const getActualDate = () => {
  return moment().format(getDateFormat())
}
