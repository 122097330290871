import { AxiosResponse } from 'axios'

import { DashboardFiltersPayload } from 'containers/Dashboard/DashboardContainer'
import { APIResponse, handleResponse } from 'utils/apiResponse.util'
import { snakeKeyToCamelCase } from 'utils/snakeToCamelCase'

import api from './api'

/**
 * Cria um novo candidato.
 *
 * @param payload - Os dados do candidato a serem criados.
 * @returns Uma promessa que resolve em uma resposta da API contendo o candidato criado.
 * returns [APIError, CandidateSchema | null]
 */
export const create = async (
  payload: CreateCandidatePayload
): Promise<APIResponse<CandidateSchema>> => {
  try {
    const { status, data }: AxiosResponse<any> = await api.post(
      '/v1/candidates/',
      payload
    )
    return handleResponse<CandidateSchema>(status, data)
  } catch (error: unknown) {
    const errorMessage = (error as Error).message
    return [errorMessage, null]
  }
}

/**
 * Cria um novo candidato.
 *
 * @param payload - Os dados do candidato a serem criados.
 * @returns Uma promessa que resolve em uma resposta da API contendo o candidato criado.
 * returns [APIError, CandidateSchema | null]
 */
export const createV2 = async (
  payload: CandidateDataV2
): Promise<APIResponse<CandidateDataV2>> => {
  try {
    const { status, data }: AxiosResponse<any> = await api.post(
      '/v1/candidates/',
      payload
    )

    return handleResponse<CandidateDataV2>(status, data)
  } catch (error: unknown) {
    const errorMessage = (error as Error).message
    return [errorMessage, null]
  }
}

export const setAttachments = async (
  payload: setAttachmentsPayload
): Promise<[APIError, boolean | null, number]> => {
  const { uuid, attachment } = payload
  const response = await api.post(
    `/v1/candidates/${uuid}/attachments`,
    attachment
  )

  if (response.status === 200) {
    return [null, true, response.status]
  } else {
    const data: ErrorSchema = response.data
    return [data.message, null, response.status]
  }
}

export const list = async ({
  page = 1,
  per_page,
  order_by,
  filter
}: {
  page: number
  per_page: number
  order_by: string
  filter: FilterCandidate
}): Promise<[APIError, Candidate[] | null]> => {
  Object.keys(filter).map(key => {
    if (!filter[key] || filter[key].length < 1 || filter[key] === '0') {
      return delete filter[key]
    }
    return true
  })
  let params
  if (order_by) {
    params = { params: { page, per_page, order_by, ...filter } }
  } else {
    params = { params: { page, per_page, ...filter } }
  }
  try {
    const response = await api.get<{
      data: CandidateSchema[]
      per_page: number
      page: number
      message?: string
    }>('/v1/candidates', params)

    if (response.status === 200) {
      return [null, response.data.data.map(snakeKeyToCamelCase)]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const detail = async (
  uuid: string
): Promise<[APIError, Candidate | null]> => {
  try {
    const response = await api.get(`/v1/candidates/${uuid}`)

    if (response.status === 200) {
      const data = response.data.data
      return [null, data]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const downloadAttachedFile = async (
  uuid: string,
  fileName: string
): Promise<[APIError, BlobPart | null]> => {
  try {
    const response = await api.get(
      `/v1/candidates/${uuid}/attachments/${fileName}`,
      { responseType: 'blob' }
    )
    if (response.status === 200) {
      return [null, response.data]
    }

    return ['error', null]
  } catch (error) {
    return [error.message, null]
  }
}

export async function applications({
  uuid,
  page = 1,
  perPage = 10
}: {
  uuid: string
  page?: number
  perPage?: number
}): Promise<[APIError, JobApplicationByCandidate[] | undefined]> {
  const response = await api.get<{
    data: JobApplicationByCandidateSchema[]
    per_page: number
    page: number
    message?: string
  }>(`/v1/candidates/${uuid}/applications`, {
    params: { page, per_page: perPage }
  })

  if (response.status === 200) {
    return [null, response.data.data.map(snakeKeyToCamelCase)]
  }

  return [response.data?.message as string, undefined]
}

export async function acquisition(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ label: string; value: number }>]> {
  const response = await api.get<{
    data: Array<{ label: string; value: number }>
    message?: string
  }>(`/v1/dashboard/job-applications/count-by-acquisitions-sources`, {
    params
  })

  if (response?.status === 200) {
    return [response?.status, response.data.data.map(snakeKeyToCamelCase)]
  }

  return [response?.status, []]
}

export async function candidateAge(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ maxAge: number; minAge: number; value: number }>]> {
  const response = await api.get<{
    data: Array<{ maxAge: number; minAge: number; value: number }>
    message?: string
  }>(`/v1/dashboard/job-applications/count-by-age-range`, { params })

  if (response?.status === 200) {
    return [response?.status, response.data.data.map(snakeKeyToCamelCase)]
  }

  return [response?.status, []]
}

export async function candidateGender(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ label: string; value: number }>]> {
  const response = await api.get<{
    data: Array<{ label: string; value: number }>
    message?: string
  }>(`/v1/dashboard/job-applications/count-by-sex`, { params })

  if (response?.status === 200) {
    return [response?.status, response?.data.data.map(snakeKeyToCamelCase)]
  }

  return [response?.status, []]
}

export async function candidateEducationLevel(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ label: string; value: number }>]> {
  const response = await api.get<{
    data: Array<{ label: string; value: number }>
    message?: string
  }>(`/v1/dashboard/job-applications/count-by-education-level`, { params })

  if (response?.status === 200) {
    return [response?.status, response.data.data.map(snakeKeyToCamelCase)]
  }

  return [response?.status, []]
}

export async function candidateMaritalStatus(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ label: string; value: number }>]> {
  const response = await api.get<{
    data: Array<{ label: string; value: number }>
    message?: string
  }>(`/v1/dashboard/job-applications/count-by-marital-status`, { params })

  if (response.status === 200) {
    return [response?.status, response.data.data.map(snakeKeyToCamelCase)]
  }

  return [response?.status, []]
}

export async function candidateByLocation(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ label: string; value: number }>]> {
  const response = await api.get<{
    data: Array<{ label: string; value: number }>
    message?: string
  }>(`/v1/dashboard/job-applications/count-by-location`, { params })

  if (response?.status === 200) {
    return [response?.status, response.data.data.map(snakeKeyToCamelCase)]
  }

  return [response?.status, []]
}

export async function lastSixMonthsCandidatesStage(
  params: DashboardFiltersPayload
): Promise<[number, Array<{ label: string; values: [] }>]> {
  const response = await api.get<{
    data: Array<{ label: string; values: [] }>
    message?: string
  }>(
    `/v1/dashboard/job-applications/count-by-hiring-workflow-stages-last-six-months`,
    { params }
  )

  if (response?.status === 200) {
    return [response?.status, response.data.data]
  }

  return [response?.status, []]
}
