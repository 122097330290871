import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Loading } from 'components/Loading/lugarh/Loading'
import SelectableList from 'components/SelectableList/lugarh/SelectableList'
import {
  initialEditUserFormValuesStepOne,
  initialEditUserFormValuesStepTwo
} from 'containers/TeamManagement/lugarh/EditUser/EditUserForm.constants'
import {
  editUserFormValidationSchemaStepOne,
  editUserFormValidationSchemaStepTwo
} from 'containers/TeamManagement/lugarh/EditUser/EditUserForm.validation'
import { useStoreNotification } from 'hooks/useStoreNotification'
import * as wsApi from 'services/api/workspaces/workspaces'

interface EditUserFormProps {
  onSubmit: (data: EditUserFormStepOne & EditUserFormStepTwo) => void
  step: number
  setStep: (step: number) => void
  userToEdit?: UserSchema | null
  disableSubmit?: boolean
  customClass?: string
  isLoading?: boolean
}

export const EditUserForm: React.FC<EditUserFormProps> = ({
  onSubmit,
  step,
  setStep,
  userToEdit,
  disableSubmit = false,
  customClass,
  isLoading = false
}) => {
  const [workspacesList, setWorkspacesList] = useState<WorkspaceSchema[]>()
  const [isEdit, setIsEdit] = useState<boolean>(!!userToEdit)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(isLoading)
  const { showNotification } = useStoreNotification()
  const { t } = useTranslation('editUserForm')
  const editUserFormValidationSchema: editUserFormValidationSchema = [
    editUserFormValidationSchemaStepOne(isEdit),
    editUserFormValidationSchemaStepTwo
  ]
  const initialEditUserFormValues = [
    initialEditUserFormValuesStepOne,
    initialEditUserFormValuesStepTwo
  ]

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<EditUserFormStepOne & EditUserFormStepTwo>({
    resolver: yupResolver(editUserFormValidationSchema[step - 1]) as any,
    defaultValues: !isEdit
      ? initialEditUserFormValues[step - 1]
      : userToEdit || {}
  })

  const handleNextStep = () => setStep(2)

  const handleFinalSubmit = (
    data: EditUserFormStepOne & EditUserFormStepTwo
  ) => {
    setLoading(true)
    onSubmit(data)
  }

  const fetchWorkspaces = async () => {
    setLoading(true)
    try {
      const response = await wsApi.list()

      if (response.status === 'error') {
        showNotification({
          message: t(`lugarh.${response.message}`),
          type: response.status
        })
      }

      response && response.data && setWorkspacesList(response.data)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectWorkspaces = (selectedWorkspaces: string[]) => {
    setSelectedItems(selectedWorkspaces)
    setValue('workspaces', selectedWorkspaces)
  }

  const getErrorMessages = ():
    | EditUserFormStepErrors['stepOne']
    | EditUserFormStepErrors['stepTwo'] => {
    if (step === 1) return errors as EditUserFormStepErrors['stepOne']
    return errors as EditUserFormStepErrors['stepTwo']
  }

  const currentErrors: EditUserFormStepErrors['stepOne'] &
    EditUserFormStepErrors['stepTwo'] = getErrorMessages()

  const setInitialWorkspaces = (): void => {
    if (userToEdit) {
      const userWorkspaces = userToEdit.workspaces.map(
        (workspace: WorkspaceSchema) => workspace.uuid
      )
      handleSelectWorkspaces(userWorkspaces)
    }
  }

  // First load
  useEffect(() => {
    fetchWorkspaces()
    isEdit && setInitialWorkspaces()
  }, [])

  // Quando troca de etapa, volta para o estado inicial
  useEffect(() => {
    isEdit ? setInitialWorkspaces() : setValue('workspaces', [])
  }, [step])

  return (
    <div className={`form__container ${customClass}`}>
      <form
        onSubmit={handleSubmit(step === 1 ? handleNextStep : handleFinalSubmit)}
      >
        {step === 1 && (
          <>
            <div className='form__row'>
              {/* FIRST NAME */}
              <div className='form__field'>
                <Controller
                  name='firstname'
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type='text'
                      autoComplete='off'
                      className={`form__input${
                        currentErrors.firstname ? ' form__input--error' : ''
                      }`}
                      placeholder={t(`lugarh.placeholder.firstname`) + '*'}
                    />
                  )}
                />
                {currentErrors.firstname && (
                  <p className='form__error'>
                    {t(`lugarh.errors.${currentErrors.firstname?.message}`)}
                  </p>
                )}
              </div>

              {/* LAST NAME */}
              <div className='form__field'>
                <Controller
                  name='lastname'
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type='text'
                      autoComplete='off'
                      className={`form__input${
                        currentErrors.lastname ? ' form__input--error' : ''
                      }`}
                      placeholder={t(`lugarh.placeholder.lastname`) + '*'}
                    />
                  )}
                />
                {currentErrors.lastname && (
                  <p className='form__error'>
                    {t(`lugarh.errors.${currentErrors.lastname?.message}`)}
                  </p>
                )}
              </div>
            </div>

            {/* EMAIL */}
            <div className='form__field'>
              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type='text'
                    autoComplete='old-email'
                    autoCapitalize='off'
                    autoCorrect='off'
                    className={`form__input${
                      currentErrors.email ? ' form__input--error' : ''
                    }`}
                    placeholder={t(`lugarh.placeholder.email`) + '*'}
                  />
                )}
              />
              {currentErrors.email && (
                <p className='form__error'>
                  {t(`lugarh.errors.${currentErrors.email?.message}`)}
                </p>
              )}
            </div>

            {/* PASSWORD */}
            <div className='form__field'>
              <Controller
                name='password'
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type='password'
                    autoComplete='new-password'
                    autoCapitalize='off'
                    autoCorrect='off'
                    className={`form__input${
                      currentErrors.password ? ' form__input--error' : ''
                    }`}
                    placeholder={t(`lugarh.placeholder.password`) + '*'}
                  />
                )}
              />
              {currentErrors.password && (
                <p className='form__error'>
                  {t(`lugarh.errors.${currentErrors.password?.message}`)}
                </p>
              )}
            </div>

            {/* ROLE (Select Field) */}
            <div className='form__field'>
              <Controller
                name='role'
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    className={`form__input${
                      currentErrors.role ? ' form__input--error' : ''
                    }`}
                  >
                    <option value=''>
                      {t(`lugarh.placeholder.role.select`)}
                    </option>
                    <option value='admin'>
                      {t(`lugarh.placeholder.role.admin`)}
                    </option>
                    <option value='manager'>
                      {t(`lugarh.placeholder.role.manager`)}
                    </option>
                    <option value='recruiter'>
                      {t(`lugarh.placeholder.role.recruiter`)}
                    </option>
                  </select>
                )}
              />
              {currentErrors.role && (
                <p className='form__error'>
                  {t(`lugarh.errors.${currentErrors.role?.message}`)}
                </p>
              )}
            </div>

            <div className='ws-modal__footer'>
              <button type='submit' className='form__button btn__primary'>
                {disableSubmit
                  ? t(`lugarh.submitButton.loading`)
                  : t(`lugarh.submitButton.stepOne`)}
              </button>
            </div>
          </>
        )}

        {step === 2 && (
          <div className='form__step-2'>
            {loading ? (
              <Loading />
            ) : (
              <>
                <SelectableList<WorkspaceSchema>
                  items={workspacesList || []}
                  onSelect={handleSelectWorkspaces}
                  labels={['name']}
                  itemIdentifier='uuid'
                  selectedItems={selectedItems}
                />
                <div className='ws-modal__footer'>
                  {currentErrors.workspaces && (
                    <p className='form__error form__error--btn-top'>
                      {t(`lugarh.errors.${currentErrors.workspaces?.message}`)}
                    </p>
                  )}
                  <button
                    type='submit'
                    className='form__button btn__primary'
                    disabled={disableSubmit}
                  >
                    {disableSubmit
                      ? t(`lugarh.submitButton.loading`)
                      : t(`lugarh.submitButton.stepTwo`)}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </form>
    </div>
  )
}
