/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
import React, { useState, useCallback, useRef } from 'react'

import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as CrossThinIcon } from 'assets/images/icons/cross-thin-icon.svg'
import { ReactComponent as JobsIcon } from 'assets/images/icons/jobs-icon.svg'
import { ReactComponent as MagnifyingGlassIcon } from 'assets/images/icons/magnifying-glass-icon.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/icons/profile-icon.svg'
import { Button } from 'components/Button/Button'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { TagList } from 'components/TagList/TagList'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'
import { search } from 'services/api'
import { timestampToDate } from 'utils/date'

import { Avatar } from '../Avatar/Avatar'

import './SearchBar.scss'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

const SearchBarComponent: React.FC = () => {
  const { showOnGeralWorkspace } = useWorkspacePermission()
  const { t } = useTranslation('searchBar')
  const [searchText, setSearchText] = useState('')
  const [results, setResults] = useState<Search>({} as Search)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const searchBarRef = useRef(null)

  const debouncedSave = useCallback(
    debounce(async nextValue => {
      if (nextValue?.length >= 3) {
        try {
          setLoading(true)
          const [, data] = await search.list({ search: nextValue })
          setResults(data as Search)
        } finally {
          setLoading(false)
        }
      }
    }, 500),
    []
  )

  useOutsideAlerter(searchBarRef, () => {
    setSearchText('')
  })

  const handleChange = (nextValue: string) => {
    setSearchText(nextValue)
    debouncedSave(nextValue)
  }

  const redirectTo = (url: string) => {
    navigate(url, { replace: true })
    navigate(0)
  }

  return (
    <>
      <div className='header-actions search-bar' ref={searchBarRef}>
        <div className={`interactive-input dark ${searchText ? 'active' : ''}`}>
          <form autoComplete='off' onSubmit={event => event.preventDefault()}>
            <input
              value={searchText}
              onChange={event => handleChange(event.target.value)}
              type='text'
              id='search-main'
              name='search_main'
              placeholder={t('placeholder')}
              role='search'
              autoComplete='off'
            />
            <div className='interactive-input-icon-wrap'>
              <svg className='interactive-input-icon icon-magnifying-glass'>
                <MagnifyingGlassIcon />
              </svg>
            </div>
            <div
              className='interactive-input-action'
              onClick={() => setSearchText('')}
            >
              <svg className='interactive-input-action-icon icon-cross-thin'>
                <CrossThinIcon />
              </svg>
            </div>
          </form>
        </div>
        <div
          className={`dropdown-box padding-bottom-small header-search-dropdown search-results ${
            searchText?.length >= 3 ? 'active' : ''
          }`}
        >
          {loading && (
            <div className='dropdown-box-category'>
              <p className='dropdown-box-category-title'>{t('searching')}</p>
            </div>
          )}
          {!loading &&
            results?.candidates?.length < 1 &&
            results?.jobs?.length < 1 && (
              <div className='dropdown-box-category'>
                <p className='dropdown-box-category-title'>{t('notFound')}</p>
              </div>
            )}

          {results?.candidates?.length > 0 && (
            <div className='dropdown-box-category'>
              <p className='dropdown-box-category-title'>
                {t('results.candidates')}
              </p>
            </div>
          )}

          <div className='dropdown-box-list small no-scroll'>
            {results?.candidates?.length ? (
              <>
                {results.candidates.map(candidate => {
                  return (
                    <div
                      key={candidate.uuid}
                      onClick={() => redirectTo(`/candidate/${candidate.uuid}`)}
                      className='dropdown-box-list-item'
                    >
                      <div className='user-status notification'>
                        <Avatar user={candidate.firstname} />
                        <div className='user-status-title'>
                          <span className='bold'>
                            {`${candidate.firstname} ${candidate.lastname}`}
                          </span>
                        </div>
                        <div className='user-status-text'>
                          {t('appliedFor', {
                            // @ts-ignore
                            number: candidate.number_of_applications
                          })}
                        </div>
                        <div className='user-status-icon'>
                          <svg className='icon-profile'>
                            <ProfileIcon />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className='p-3 w-100'>
                  <Button
                    className='secondary'
                    onClick={() => navigate('/candidates-database')}
                  >
                    {t('allCandidates')}
                  </Button>
                </div>
              </>
            ) : null}
          </div>

          {results?.jobs?.length > 0 && (
            <div className='dropdown-box-category'>
              <p className='dropdown-box-category-title'>{t('results.jobs')}</p>
            </div>
          )}

          <div className='dropdown-box-list small no-scroll'>
            {results?.jobs?.length > 0 && (
              <>
                {results.jobs.map((job: any) => {
                  return (
                    <div
                      key={job.uuid}
                      onClick={() => redirectTo(`/candidates-list/${job.uuid}`)}
                      className='dropdown-box-list-item'
                    >
                      <div className='user-status notification'>
                        <Avatar user={job.name} />
                        <div className='user-status-title'>
                          <span className='bold'>{job.name}</span>
                        </div>
                        {showOnGeralWorkspace && (
                          <div className='user-status-workspace'>
                            <span className='bold'>
                              {job.workspaces[0].name}
                            </span>
                          </div>
                        )}
                        <div className='user-status-text'>
                          {t('createdAt')} {timestampToDate(job.created_at)}
                        </div>
                        <div className='user-status-text'>{job.city}</div>
                        <div className='user-status-text'>
                          {
                            // @ts-ignore
                            job.number_of_applications
                          }{' '}
                          {t('candidates')}
                        </div>
                        <div className='user-status-text'>
                          <TagList
                            onClick={() => null}
                            tags={
                              // @ts-ignore
                              job.tags
                            }
                          />
                        </div>
                        <div className='user-status-icon'>
                          <svg className='icon-timeline'>
                            <JobsIcon />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className='p-3 w-100'>
                  <Button
                    className='secondary'
                    onClick={() => navigate('/jobs')}
                  >
                    {t('allJobs')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const SearchBar: React.FC = () => (
  <PermissionAgent
    module='search'
    actions={['view']}
    onFail='hidden'
    Component={({ hidden }) => (hidden ? null : <SearchBarComponent />)}
  />
)
