import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Loading } from 'components/Loading/Loading'
import { CreateUserModal } from 'components/Modals/lugarh/CreateUser/CreateUserModal'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { TeamCard, TeamCardRefs } from 'components/TeamCard/TeamCard'
import { ConfirmOperationModal } from 'containers/TeamManagement/ConfirmOperationModal/ConfirmOperationModal'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { users } from 'services/api'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import ReactGA from 'utils/analytics'

type Props = {
  getRefs: (value: TeamCardRefs) => void
}

export const TeamManagementContainer: React.FC<Props> = ({ getRefs }) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const { selectors, dispatch } = useStore()
  const { t } = useTranslation('teamManagement')
  const [pageData, setPageData] = useState({
    page: 1,
    per_page: 10,
    allUsers: true
  })
  const [teamMemberList, setTeamMemberList] = useState<Record<string, User>>({})
  const [blockAdm, setBlockAdm] = useState('')
  const [hasNewData, setHasNewData] = useState(false)

  const [hasMore, setHasMore] = useState(true)
  const [ref, setRefs] = useState<TeamCardRefs>({} as TeamCardRefs)
  const [addButtonRef, setAddButtonRef] = useState<TeamCardRefs>(
    {} as TeamCardRefs
  )
  const [openConfirmOperationModal, setOpenConfirmOperationModal] =
    useState(false)
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserSchema | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [editMySelf, setEditMySelf] = useState(false)

  const loggedUser = selectors.auth.user()

  const [selectedOperation, setSelectedOperation] = useState<
    'blockModal' | 'unblockModal' | 'deleteModal' | ''
  >('')

  useEffect(() => {
    const refs = {
      ...addButtonRef,
      ...ref
    }
    getRefs(refs)
  }, [addButtonRef, ref])

  useEffect(() => {
    fetchUsers()
    setHasNewData(false)
  }, [hasNewData])

  useEffect(() => {
    const adms = Object.values(teamMemberList).filter(el => el.role === 'admin')
    setBlockAdm(adms[adms.length - 1]?.uuid)
  }, [teamMemberList])

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const fetchUsers = async () => {
    setIsLoading(true)
    try {
      const [error, data] = await users.read(pageData)
      if (error) throw new Error(error.toString())

      const newMap = {}
      data?.data.forEach(member => {
        newMap[member.uuid] = member
      })
      setTeamMemberList({ ...teamMemberList, ...newMap })
      setHasMore(data?.data?.length === pageData.per_page)
      setPageData({
        page: pageData.page + 1,
        per_page: pageData.per_page,
        allUsers: true
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeStatus = user => {
    const operation = user?.active ? 'blockModal' : 'unblockModal'

    setSelectedOperation(operation)
    setSelectedUser(user)
    setOpenConfirmOperationModal(true)

    ReactGA.event(`${operation.split('Modal')[0]}_user_start`, {
      event_category: 'team_management',
      event_label: `${operation.split('Modal')[0]}_user_start`
    })
  }

  const handleDelete = user => {
    setSelectedOperation('deleteModal')
    setSelectedUser(user)
    setOpenConfirmOperationModal(true)

    ReactGA.event('delete_user_start', {
      event_category: 'team_management',
      event_label: 'delete_user_start'
    })
  }

  const handleCreateUser = () => {
    setSelectedUser(null)
    setOpenEditUserModal(true)

    ReactGA.event('create_user_start', {
      event_category: 'team_management',
      event_label: 'create_user_start'
    })
  }

  const handleEditUser = (user: UserSchema) => {
    if (user.uuid === loggedUser?.uuid) {
      setEditMySelf(true)
    }
    setSelectedUser(user)
    setOpenEditUserModal(true)

    ReactGA.event('edit_user_start', {
      event_category: 'team_management',
      event_label: 'edit_user_start'
    })
  }

  const updateUserStatus = async () => {
    try {
      const uuid = selectedUser?.uuid || ''
      setIsLoading(true)
      const payload = {
        uuid,
        user: {
          active: !selectedUser?.active
        }
      }
      const [error, data] = await users.edit(payload)
      if (error) throw new Error(error.toString())
      setTeamMemberList({
        ...teamMemberList,
        [uuid]: { ...teamMemberList[uuid], active: !selectedUser?.active }
      })
      setOpenConfirmOperationModal(false)
      setSelectedUser(null)
      setSelectedOperation('')
      showNotification({
        message: data?.active
          ? t('unblockModal.success')
          : t('blockModal.success'),
        type: 'success'
      })

      const operation = data?.active ? 'unblock' : 'block'

      ReactGA.event(`${operation}_user_finish`, {
        event_category: 'team_management',
        event_label: `${operation}_user_finish`
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const removeUser = async () => {
    try {
      setIsLoading(true)
      const uuid = selectedUser?.uuid || ''
      const [error] = await users.remove(uuid)
      if (error) throw new Error(error.toString())
      const newTeamMemberList = teamMemberList
      delete newTeamMemberList[uuid]
      setTeamMemberList({ ...newTeamMemberList })
      setOpenConfirmOperationModal(false)
      setSelectedUser(null)
      setSelectedOperation('')
      showNotification({ message: t('deleteModal.success'), type: 'success' })

      ReactGA.event('delete_user_finish', {
        event_category: 'team_management',
        event_label: 'delete_user_finish'
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const createUser = async (user: UserPayload) => {
    setIsLoading(true)
    try {
      const payload = { user }
      const [error, data] = await users.create(payload)
      if (error) throw new Error(error.toString())
      const uuid = data?.uuid || ''
      setTeamMemberList({ ...teamMemberList, [uuid]: data as User })
      setOpenEditUserModal(false)
      setSelectedUser(null)
      // Close Modal ---------
      setOpenEditUserModal(false)
      setSelectedUser(null)
      setEditMySelf(false)
      // ---------------------
      showNotification({ message: t('addModal.success'), type: 'success' })
      setHasNewData(true)

      ReactGA.event('create_user_finish', {
        event_category: 'team_management',
        event_label: 'create_user_finish'
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const editUser = async (user: UserPayload) => {
    setIsLoading(true)
    try {
      const payload = {
        uuid: selectedUser?.uuid || '',
        user: {
          email: user.email || undefined,
          firstname: user.firstname || undefined,
          lastname: user.lastname || undefined,
          role: user.role || undefined,
          password: user.password || undefined,
          workspace: user.workspaces || undefined
        }
      }

      const [error, data] = await users.edit(payload)
      if (error) throw new Error(error.toString())
      const uuid = data?.uuid || ''
      setTeamMemberList({ ...teamMemberList, [uuid]: data as User })
      setOpenEditUserModal(false)
      setSelectedUser(null)
      // Close modal ---------
      setOpenEditUserModal(false)
      setSelectedUser(null)
      setEditMySelf(false)
      // ---------------------
      showNotification({ message: t('editModal.success'), type: 'success' })
      setHasNewData(true)

      ReactGA.event('edit_user_finish', {
        event_category: 'team_management',
        event_label: 'edit_user_finish'
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <CreateUserModal
        userToEdit={selectedUser}
        onConfirm={user => (selectedUser ? editUser(user) : createUser(user))}
        open={openEditUserModal}
        onClose={() => {
          setOpenEditUserModal(false)
          setSelectedUser(null)
          setEditMySelf(false)
        }}
        loading={isLoading}
        customClass='user-modal'
      />
      <ConfirmOperationModal
        user={`${selectedUser?.firstname} ${selectedUser?.lastname}`}
        selectedOperation={selectedOperation}
        isLoading={isLoading}
        open={openConfirmOperationModal}
        onClose={() => {
          setOpenConfirmOperationModal(false)
          setSelectedUser(null)
          setSelectedOperation('')
        }}
        onConfirm={() => {
          if (selectedOperation === 'deleteModal') removeUser()
          else updateUserStatus()
        }}
      />
      {/* <EditUserModal
        isLoading={isLoading}
        open={openEditUserModal}
        selected={selectedUser as UserSchema}
        editMySelf={editMySelf}
        onClose={() => {
          setOpenEditUserModal(false)
          setSelectedUser({} as UserSchema)
          setEditMySelf(false)
        }}
        onConfirm={(user, isEdit) => {
          if (!isEdit) return createUser(user)
          else return editUser(user)
        }}
      /> */}
      <InfiniteScroll
        dataLength={Object.values(teamMemberList).length} // This is important field to render the next data
        next={fetchUsers}
        hasMore={hasMore}
        style={{ overflow: 'hidden' }}
        loader={<Loading />}
        className='grid grid-3-3-3-3 centered'
      >
        {dontShowOnGeralWorkspace && (
          <PermissionAgent
            module='user'
            actions={['create']}
            onFail='hidden'
            Component={({ hidden }) =>
              hidden ? null : (
                <TeamCard
                  status='add-new-team'
                  onCreateNewUser={handleCreateUser}
                  getRefs={refs => {
                    !addButtonRef && setAddButtonRef(refs)
                  }}
                />
              )
            }
          />
        )}

        {Object.values(teamMemberList).map(member => {
          return (
            <TeamCard
              key={member.uuid}
              status={member.active ? 'active' : 'disabled'}
              name={`${member.firstname} ${member.lastname}`}
              role={member.role}
              workspaceList={member.workspaces}
              blockActions={member.uuid === blockAdm}
              onBlock={() => handleChangeStatus(member)}
              onDelete={() => handleDelete(member)}
              onEdit={() => handleEditUser(member)}
              onReactivate={() => handleChangeStatus(member)}
              getRefs={refs => setRefs({ ...refs })}
            />
          )
        })}
      </InfiniteScroll>
    </>
  )
}
